import axios from "axios";
import options from "./axios_options";

const _axios = axios.create(options);

_axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 403) {
      if (
        !error.request.responseURL.endsWith("/clients/ordering/clients/oep/")
      ) {
        document.cookie =
          "osteonAccessToken=; expires= Thu, 21 Aug 2014 16:00:00 UTC; path=/";
        window.location = `${options.authURL}?client_id=${
          options.clientID
        }&response_type=token&redirect_uri=${encodeURIComponent(
          options.redirectURI
        )}`;
      }
    }
    return Promise.reject(error);
  }
);

function install(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
}

export default install;
