<template>
  <v-card height="100%" width="100%" flat color="transparent" class="mt-2">
    <v-card outlined max-width="600" class="rounded-xl mx-auto">
      <v-toolbar dark flat color="secondary">
        <v-toolbar-title><v-icon>mdi-logout</v-icon> Logout</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p class="text-center pt-5">
          <v-btn x-large color="error" rounded @click="logout()">
            <v-icon class="mr-2">mdi-logout</v-icon>
            Logout
          </v-btn>
        </p>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import options from "@/plugins/axios/axios_options";

export default {
  name: "LogOut",
  methods: {
    logout() {
      this.$axios.defaults.headers.Authorization = null;
      this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
      window.location.href = options.logoutURL;
    },
  },
};
</script>
